import React from 'react';
import mast from '../img/Aeroseal_Masthead_FAQ.png';
import formBg from '../img/Aeroseal_BG_Contact.jpg';
import Accordion from '../components/Accordion';
import ContactForm from '../components/ContactForm';
import BotttomLogoSection from '../components/BottomLogoSection';

import '../styles/FaqTemplate.scss';

const FaqTemplate = (props) => {
	return (
		<main className="FaqTemplate">
			<section className="hero is-light is-bold">
				<div
					className="hero-body"
					style={{
						backgroundImage: `url(${mast})`,
					}}
				>
					<div className="container">
						<h1>
							{props.content.heading}
						</h1>
					</div>
				</div>
			</section>

			<section className="section" style={{ marginBottom: '-4rem' }}>
				<div className="container">
					<Accordion
						items={props.content.faqs}
					/>
				</div>
			</section>

			<section
				className="is-inverted is-skewed"
				style={{
					backgroundImage: `url(${formBg})`,
					backgroundPosition: 'center center',
					backgroundSize: 'cover',
				}}
			>
				<div
					className="section"
					style={{
						paddingTop: '16rem',
						paddingBottom: '12rem',
						marginBottom: '-6rem',
					}}
				>
					<div className="container">
						<div className="columns">
							<div className="column">
								<div className="contactInner">
									<h2>Have another question you'd like answered?</h2>
									<p>Fill out our form or call us, anytime.</p>
									<h3>
										<a className="phonelink" href="tel:17052149610">
											(705)&nbsp;214-9610
										</a>
									</h3>
								</div>
							</div>
							<div className="column has-text-centered">
								<div className="contactInner">
									<ContactForm />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<BotttomLogoSection />
		</main>
	);
};

export default FaqTemplate;
