import React from 'react';
import '../styles/Accordion.scss';
import minus from '../img/Minus.svg';
import plus from '../img/Plus.svg';

const AccordionItem = ({ title, content, active, update }) => (
	<div className="AccordionItem" onClick={update}>
		<div className="columns is-mobile">
			<div className="column is-narrow">
				<img src={active ? minus : plus} alt={active ? 'minus' : 'plus'} />
			</div>
			<div className="column">
				<h3>{title}</h3>
			</div>
		</div>
		<p className={active ? 'is-tuckable' : 'is-tuckable is-tucked'}>
			{content}
		</p>
	</div>
);

class Accordion extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			index: 0,
		};
	}
	update = (newIndex) => {
		this.setState(
			(state) =>
				newIndex === state.index
					? { open: !state.open, index: newIndex }
					: { open: true, index: newIndex }
		);
	};
	render() {
		return (
			<div className="Accordion">
				{this.props.items.map((item, index) => (
					<AccordionItem
						title={item.question}
						content={item.answer}
						key={index}
						update={() => this.update(index)}
						active={this.state.open === true && this.state.index === index}
					/>
				))}
			</div>
		);
	}
}

export default Accordion;
